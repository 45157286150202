<script>
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Perks from "./blocks/Perks.svelte";
  import Logo from "./blocks/Logo.svelte";
  import Badges from "./blocks/Badges.svelte";
  import ClickToAction from "./blocks/ClickToAction.svelte";
  import Bonus from "./blocks/Bonus.svelte";
  import { translate } from "$lib/common/helpers/translate";
  import { formatValue } from "$lib/common/helpers/formatValue";

  export let props = null;
  export let operator = null;
  export let project = null;
  export let visibleOrNot = null;
  export let dataElement = null;
  export let toplistPosition = null;

  const lang = $project.settings.lang ?? "en";
  const currency = $project.settings.currency.toUpperCase();
  const primaryColor =
    operator.visuals[$project.key][$project.settings.market].primary_color;

  $: formattedPrimaryColor = primaryColor.replace(/\s+/g, "");
  $: borderColor =
    formattedPrimaryColor === "255,255,255,1" ? "" : `rgba(${primaryColor})`;

  // Perks Data:
  // Withdrawal experience 1 & 2
  const withdrawalExperienceRound1 =
    operator?.data?.ownExperience?.withdrawalExperience?.round1;
  const withdrawalExperienceRound2 =
    operator?.data?.ownExperience?.withdrawalExperience?.round2;

  // Min Withdrawal Deposit
  const minWithDrawal =
    operator?.data?.withdrawalLimits?.min[$project.settings.market].min +
    " " +
    currency;

  // Deposit Wagering
  const depositWagering =
    operator?.data?.withdrawalLimits?.min[$project.settings.market].wagering +
    "x";

  // Withdrawal Methods
  const withdrawalMethods =
    operator?.data?.withdrawal[$project.settings.market];

  const perksData = [
    {
      title: translate(["Withdrawal attempt", "One"], lang),
      value: formatValue(withdrawalExperienceRound1?.result, "min", {
        paymentMethod: withdrawalExperienceRound1?.payment_method,
        lang,
      }),
    },
    {
      title: translate(["Withdrawal attempt", "Two"], lang),
      value: formatValue(withdrawalExperienceRound2?.result, "min", {
        paymentMethod: withdrawalExperienceRound2?.payment_method,
        lang,
      }),
    },
    {
      title: translate("Min Withdrawal", lang),
      value: minWithDrawal,
    },
    {
      title: translate("Deposit wagering", lang),
      value: depositWagering,
    },
    {
      title: translate("Withdrawal methods", lang),
      value: withdrawalMethods?.join(", ") ?? "-",
    },
  ];
</script>

<div style="display: {visibleOrNot}">
  <div class="operator-container" style="border-color: {borderColor};">
    <Logo {operator} {primaryColor} {dataElement} {toplistPosition} />
    <Badges {operator} {project} />
    <Bonus {operator} {project} />
    <Perks perks={perksData} theme="fast-withdrawals" />
    <ClickToAction
      {operator}
      {project}
      {props}
      {dataElement}
      {toplistPosition}
    />
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    position: relative;
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-template-rows: 0fr 55px 0fr 1fr;
    grid-template-areas:
      "LOGO BONUS"
      "LOGO BADGES"
      "LOGO CTA"
      "PERKS PERKS";
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid rgba(217, 217, 217, 1);
    overflow: hidden;
    @media only screen and (min-width: 992px) {
      height: 190px;
      grid-auto-flow: column;
      grid-template-columns: 165px 55px 1.2fr 1.7fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA";
    }
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    & a {
      color: var(--clr-qua);
      text-decoration: underline;
      font-weight: 300;
    }
  }
</style>
