<script lang="ts">
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import { page } from "$app/stores";
  import type { Operator } from "@/src/types/components/operators/Operator";
  export let operator: Operator;
  export let project;
  export let props: Record<string, any> = {};
  export let dataElement: string;
  export let toplistPosition: number;

  let reviewLink =
    `/${$project.data?.categoryPages.reviews.slug}/${operator?.name_lower}` ??
    "";
</script>

<div class="cta-container">
  <!-- Operator External Link -->
  <a
    href={`/${$project.settings.redirectUrl}/${operator.name_lower}`}
    target="_blank"
    rel="nofollow noreferrer noopener"
    class="cta"
    data-element={dataElement}
    data-operator={operator.name_lower}
    data-type="button"
    data-toplist-pos={toplistPosition}
  >
    <div class="cta-text-strong">
      <Translate
        text={"Visit" + (props.toplistFocus == "casino" ? " casino" : "")}
      />
    </div>
    <div class="cta-text-small">
      <Translate text="Proceed to" />
      {operator["name"]}
    </div>
  </a>
  <!-- Operator Review Link -->
  {#if $page.url.pathname != `/${$project.data?.categoryPages.reviews.slug}/${operator.name_lower}` && reviewLink}
    <a class="cta-review" href={reviewLink}
      >{operator["name"]} <Translate text="Review" /></a
    >
  {/if}
</div>

<style>
  .cta-container {
    grid-area: CTA;
    margin: 5px 5px 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    @media only screen and (min-width: 992px) {
      margin: 0;
      padding: 5px 5px 0 5px;
      width: 100%;
      max-width: 230px;
      justify-content: center;
      padding: 10px 10px 10px 0;
    }
  }
  .cta-container .cta {
    width: 100%;
    display: block;
    color: #fff;
    border-radius: var(--bd-rad-sm);
    background-color: var(--clr-cta);
    text-align: center;
    padding: 10px;
    white-space: nowrap;
  }
  .cta-container .cta .cta-text-strong {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .cta-container .cta .cta-text-small {
    font-size: 13px;
    font-weight: 300;
  }
  .cta-container .cta-review {
    padding: 6px;
    width: 100%;
    border: 1px solid var(--clr-qua);
    border-radius: var(--bd-rad-sm);
    font-size: 14px;
    text-align: center;
  }
</style>
