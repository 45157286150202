<script lang="ts">
  import { project } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import type { Translations, Language } from "@/src/types/languages";

  const typedTranslations = translations as Translations;

  let lang = ($project.settings.lang ?? "en") as Language;

  export let operator: Operator;
  export let focus = "casino";

  let termsHTML: string | null = null;

  $: {
    if (!operator || !operator?.bonus?.[focus]?.[$project.settings.market]?.tc) {
      termsHTML = null;
      if ($project.key === "cf" && $project.settings.market === "se") {
        termsHTML = `Reklamlänk | 18+ | <a style='font-weight: 300; text-decoration: underline;' href=${
          operator?.name_lower ? "/spela/" + operator?.name_lower + "/villkor" : null
        } target="_blank" rel="nofollow noreferrer noopener" data-operator='${
          operator?.name_lower
        }' data-type="terms">Regler &amp; villkor gäller</a> | Spela ansvarsfullt | <a style='font-weight: 300; text-decoration: underline;' href="https://www.stodlinjen.se" target="_blank" rel="nofollow noreferrer noopener">stodlinjen.se</a>`;
      } else if ($project.key === "cf-ca") {
        termsHTML = `Affiliate link | 19+ | T&Cs Apply | Play responsible | This offer is not available for players residing in Ontario`;
      } else {
        termsHTML = typedTranslations["New customers. 18+. Gamble responsibly."][lang] ?? "";
      }
    }
    if ($project.settings.lang === "sv" && operator?.bonus?.[focus]?.[$project.settings.market]?.tc) {
      termsHTML = operator?.bonus?.[focus]?.[$project.settings.market]?.tc
        .replace(
          "Regler & villkor gäller",
          `<a style='font-weight: 300; text-decoration: underline;' href='/spela/${operator?.name_lower}/villkor' target="_blank" rel="nofollow">Regler & villkor gäller</a>`,
        )
        .replace(
          "stodlinjen.se",
          `<a style='font-weight: 300; text-decoration: underline;' href='https://www.stodlinjen.se/' target="_blank" rel="nofollow">stodlinjen.se</a>`,
        )
        .replace(
          "Stodlinjen.se",
          `<a style='font-weight: 300; text-decoration: underline;' href='https://www.stodlinjen.se/' target="_blank" rel="nofollow">stodlinjen.se</a>`,
        )
        .replace(
          "spelpaus.se",
          `<a style='font-weight: 300; text-decoration: underline;' href='https://www.spelpaus.se' target='_blank' rel='nofollow'>spelpaus.se</a>`,
        )
        .replace(
          "Spelpaus.se",
          `<a style='font-weight: 300; text-decoration: underline;' href='https://www.spelpaus.se' target='_blank' rel='nofollow'>spelpaus.se</a>`,
        )
        .replace(
          "Fullständiga Regler & Villkor gäller",
          `<a style='font-weight: 300; text-decoration: underline;' href='/spela/${operator?.name_lower}/villkor' target="_blank" rel="nofollow">Fullständiga Regler & Villkor gäller</a>`,
        );
    }
  }
</script>

{#if termsHTML}
  {@html termsHTML}
{/if}
