<script>
  export let perks;
  export let theme = "default";
</script>

<div class={`perks-container ${theme}`}>
  {#each perks as perk}
    <div class="perk">
      <span class="title">{perk.title}</span>
      <span class="value">{perk.value}</span>
    </div>
  {/each}
</div>

<style>
  .perks-container {
    grid-area: PERKS;
    padding: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-self: center;
    @media only screen and (min-width: 992px) {
      padding: 10px;
      max-width: 450px;
    }
  }
  /* Themes definitions */
  .new-casinos {
    grid-template-columns: repeat(2, 1fr);
    & .perk:last-child {
      grid-column: 1 / span 2;
    }
  }
  .payment-method {
    grid-template-columns: repeat(4, 1fr);
  }
  .no-account {
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
    & .perk:last-child {
      grid-area: 2 / 1 / 3 / 4;
    }
  }

  .casino-bonus {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);

    & .perk {
      &:nth-child(1) {
        grid-column: 1 / span 1;
      }
      &:nth-child(2) {
        grid-column: 2 / span 2;
      }
      &:nth-child(3) {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
      }
      &:nth-child(4) {
        grid-row: 2 / span 1;
        grid-column: 2 / span 2;
      }
    }
  }

  .freespins-bonus {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);

    & .perk {
      &:nth-child(1) {
        grid-column: 1 / span 1;
      }
      &:nth-child(2) {
        grid-column: 2 / span 2;
      }
      &:nth-child(3) {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
      }
      &:nth-child(4) {
        grid-row: 2 / span 1;
        grid-column: 2 / span 2;
      }
    }
  }
  .fast-withdrawals {
    grid-template-columns: repeat(3, 1fr);
    & .perk:last-child {
      grid-column: 2 / span 2;
    }
  }

  .perk {
    width: 100%;
    display: flex;
    border-radius: 4px;
    justify-content: space-between;
    padding: 6px;
    flex-direction: column;
    background-color: #efefef;
  }
  .title {
    font-size: 11px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
  }
  .value {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    color: #000;
    text-transform: capitalize;
  }
</style>
