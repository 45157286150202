<script lang="ts">
  import { fly } from "svelte/transition";
  import Alert from "$lib/main/components/Alert.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import type { Message } from "@/src/types/components/Manage";

  // Initialize message with explicit type
  export let message: Message = { success: null, display: null };
  export let name = "Item";
  export let remove: (() => void) | null = null;

  export let toggleRemove = false;

  $: if (message?.display) {
    setTimeout(() => {
      message.display = null;
    }, 7000);
  }
</script>

<slot />

{#if message?.display}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="message"
    in:fly={{ x: 50, duration: 200, delay: 200 }}
    out:fly={{ x: 50, duration: 200 }}
    on:click={() => (message.display = null)}
  >
    <Alert type={message.success ? "success" : "danger"}
      >{message.display}</Alert
    >
  </div>
{/if}

<Modal bind:toggleModal={toggleRemove}>
  <svelte:fragment slot="header">Remove {name}</svelte:fragment>
  <p>Do you want to remove <code>{name}</code>?</p>
  <button
    on:click={() => {
      if (remove) remove();
      toggleRemove = !toggleRemove;
    }}>Remove</button
  >
  <button
    on:click={() => {
      toggleRemove = !toggleRemove;
    }}>Cancel</button
  >
</Modal>
