
<script>
  import { PaymentMethods } from "$lib/common/enums/paymentMethods";

  export let operator;
  export let project;

  const depositMethods =
    operator?.data?.deposit?.[$project.settings.market] ?? [];

  const isMethodAvailable = (methodName) => depositMethods.includes(methodName);

  function getIconUrl(iconName) {
    const basePath = $project?.data?.imageFolders?.icons ?? "/default/path/";
    return `${basePath}${iconName}.png?h=54&q=100&auto=format`;
  }
</script>

<div class="badges-container">
  <div class="badge-item {isMethodAvailable(PaymentMethods.TRUSTLY) ? '' : 'disabled'}">
    <img
      loading="lazy"
      class="badge-icon"
      src={getIconUrl("trustly")}
      alt={`${operator["name"]} har Trustly`}
    />
    <span>Trustly</span>
  </div>
  <div class="badge-item {isMethodAvailable(PaymentMethods.SWISH) ? '' : 'disabled'}">
    <img
      loading="lazy"
      class="badge-icon"
      src={getIconUrl("swish")}
      alt={`${operator["name"]} har Swish`}
    />
    <span>Swish</span>
  </div>
  <div class="badge-item">
    <img
      loading="lazy"
      class="badge-icon"
      src={getIconUrl("bankid")}
      alt={`${operator["name"]} har BankID`}
    />
    <span>BankID</span>
  </div>
  <div class="badge-item">
    <img
      loading="lazy"
      class="badge-icon"
      src={getIconUrl("spelinspektionen")}
      alt={`${operator["name"]} har licens fra Spelinspektionen`}
    />
    <span>Licens</span>
  </div>
</div>

<style>
  .badges-container {
    grid-area: BADGES;
    margin: 0 5px;
    display: flex;
    gap: 7px;
    border-radius: var(--bd-rad-sm);
    height: 55px;
    padding: 6px;
    background-color: #efefef;
    font-weight: 300;
    font-size: 12px;
    justify-content: space-evenly;
    @media only screen and (min-width: 992px) {
      margin: 0;
      width: 100%;
      justify-content: center;
      border-radius: 0;
      flex-direction: column;
      max-width: 55px;
      height: 100%;
    }
    & .badge-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      @media only screen and (min-width: 992px) {
        gap: 5px;
        font-size: 10px;
      }
      &.disabled {
        opacity: 0.3;
      }
      & .badge-icon {
        height: 20px;
        opacity: 0.8;
        @media only screen and (min-width: 992px) {
          height: 12px;
        }
      }
      & span {
        line-height: normal;
      }
    }
  }
</style>
