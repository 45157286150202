import { translations } from "../../data/translations";
// import { translations } from "@/src/lib/data/translations";

export function translate(
  texts: string | string[],
  lang: string = "en"
): string {
  const textsArray = Array.isArray(texts) ? texts : [texts];

  return textsArray.map((text) => translations[text]?.[lang] ?? text).join(" ");
}
