import type { Tables } from "@/src/types/database";
import { PUBLIC_SUPABASE_URL } from "$env/static/public";

/*
 * Common function for generate bucket link
 */
export const getBucketLink = (): string => {
  return process.env.NODE_ENV === "development"
    ? `${PUBLIC_SUPABASE_URL}/storage/v1/object/public/content/cf/images/`
    : "https://media.casinofeber.se/";
};

/*
 * Common function for generate operator logo public url
 */
export const getOperatorLogo = (
  project: Tables<"projects">,
  logo: { [key: string]: string } = {}
) => {
  const path = getBucketLink() + "operators";
  const fileName = logo[project?.key] || "notfound.png";
  return fileName ? `${path}/${fileName}` : null;
};
