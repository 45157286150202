<script>
  import { project } from "$lib/services/store"
  import { numberSpacing } from '$lib/services/utils'

  export let amount
</script>

{#if $project.settings.market === "se"
  || $project.settings.market === "no"}
  {numberSpacing(amount)} kr
{:else if $project.settings.market === "ca"}
  $C {numberSpacing(amount)}
{/if}