<script>
  export let colspan = "1";
  export let addClass = "";
  export let heading = null;
</script>

<div class={"grid col-" + colspan + " " + addClass} class:heading>
  {#if heading}<h4>{heading}</h4>{/if}
  <slot>No data provided.</slot>
</div>

<style>
  h4 {
    margin: 0;
  }

  :global(.form-item:last-of-type, .separator:last-of-type) {
    border: none;
  }

  .separator {
    padding: 0;
  }

  .grid {
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
  }
  :global(.grid > *) {
    min-width: 0;
  }
  .grid.inline {
    display: flex;
  }
  .grid.align-right {
    justify-content: flex-end;
  }
  .grid.manage {
    justify-items: baseline;
  }
  .grid.p-xl {
    row-gap: 2rem;
    column-gap: 2rem;
  }
  .grid.p-l {
    row-gap: 1.5rem;
    column-gap: 1.5rem;
  }
  .grid.narrow,
  .grid.p-s {
    row-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .grid.no-space,
  .grid.p-xs {
    row-gap: 0;
    column-gap: 0;
  }
  .grid.sub-section {
    background: var(--bg-clr-l);
  }

  .grid.col-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid.col-1-auto {
    grid-template-columns: 1fr auto;
  }
  .grid.col-1-auto-auto {
    grid-template-columns: 1fr auto auto;
  }
  .grid.col-1-1-3-6 {
    grid-template-columns: 1fr 1fr 3fr 6fr;
  }
  .grid.col-1-3-4-4 {
    grid-template-columns: 1fr 3fr 4fr 4fr;
  }
  .grid.col-1-9-2 {
    grid-template-columns: 1fr 9fr 2fr;
  }
  .grid.col-1-10-auto {
    grid-template-columns: 1fr 10fr auto;
  }
  .grid.col-1-10-1 {
    grid-template-columns: 1fr 10fr 1fr;
  }
  .grid.col-1-11 {
    grid-template-columns: 1rem 1fr;
  }
  .grid.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid.col-2-1-3-5 {
    grid-template-columns: 2fr 1fr 3fr 5fr;
  }
  .grid.col-2-5-4-1 {
    grid-template-columns: 2fr 5fr 4fr 1fr;
  }
  .grid.col-2-6-3-auto {
    grid-template-columns: 2fr 6fr 3fr auto;
  }
  .grid.col-2-10 {
    grid-template-columns: 1fr 5fr;
  }
  .grid.col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  :global(.grid.col-3 .col-full) {
    grid-column: span 3;
  }
  .grid.col-3-9 {
    grid-template-columns: 1fr 3fr;
  }
  .grid.col-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid.col-4-5-2-1 {
    grid-template-columns: 4fr 5fr 2fr 1fr;
  }
  .grid.col-4-6-2 {
    grid-template-columns: 2fr 3fr 1fr;
  }
  .grid.col-4-7-1 {
    grid-template-columns: 4fr 7fr 1fr;
  }
  .grid.col-4-8 {
    grid-template-columns: 1fr 2fr;
  }
  .grid.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .grid.col-5-6-1 {
    grid-template-columns: 5fr 6fr 1fr;
  }
  .grid.col-6 {
    grid-template-columns: repeat(6, 1fr);
  }
  .grid.col-6-2-2-2 {
    grid-template-columns: 3fr 1fr 1fr 1fr;
  }
  .grid.col-6-5-1 {
    grid-template-columns: 6fr 5fr 1fr;
  }
  .grid.col-7 {
    grid-template-columns: repeat(7, 1fr);
  }
  .grid.col-8-2-2 {
    grid-template-columns: 4fr 1fr 1fr;
  }
  .grid.col-8-4 {
    grid-template-columns: 2fr 1fr;
  }
  .grid.col-9-3 {
    grid-template-columns: 3fr 1fr;
  }
  .grid.col-10-2 {
    grid-template-columns: 5fr 1fr;
  }
  .grid.col-11-1 {
    grid-template-columns: 11fr 1fr;
  }
</style>
