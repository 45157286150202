<script>
  import CurrencyAmount from "$lib/admin/components/blocks/helpers/CurrencyAmount.svelte";
  export let bonus;
  export let label;
  export let fallback;
  export let turnover;
  export let gridArea;
</script>

<div class="bonus-container" style="grid-area: {gridArea};">
  {#if bonus}
    <div class="bonus">
      <span class="amount">
        {#if label === "Bonus"}
          <CurrencyAmount amount={bonus} />
        {:else}
          {bonus}
        {/if}</span>
      <span class="label">{label}</span>
    </div>
    <div class="requirement">
      <span>oms.krav:</span>
      <span class="value">{turnover}x</span>
    </div>
  {:else if fallback}
    <div class="bonus fallback">
      <span class="label">{fallback}</span>
    </div>
  {/if}
</div>

<style>
  .bonus-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  .bonus {
    font-weight: 300;
    font-size: 22px;
    color: #333;
    line-height: 1.3;
    text-wrap: nowrap;
    & span {
      font-size: 16px;
      font-weight: 400;
      &.amount {
        font-weight: 400;
        font-size: 20px;
        color: var(--clr-qua-alt);
        color: #5cb629;
      }
      &.label {
        font-weight: 300;
      }
    }
  }

  .bonus.fallback {
    text-wrap: wrap;
    text-align: center;
  }

  .bonus-container .requirement {
    display: flex;
    font-weight: 300;
    gap: 2px;
    font-size: 11px;

    .value {
      font-weight: 400;
    }
  }
</style>
