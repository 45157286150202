<script lang="ts">
  import Rating from "$lib/admin/components/blocks/helpers/Rating.svelte";
  import Ribbon from "$lib/admin/components/blocks/helpers/Ribbon.svelte";
  import { project } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import { getOperatorLogo } from "$lib/common/helpers/image";

  export let rating = true;
  export let ribbon = true;
  export let operator: Operator;
  export let primaryColor: string;
  export let listIndex: number | null = null;
  export let dataElement: string;
  export let toplistPosition: number;
  export let isTournamentLink: boolean = false;

  let projectKey = $project.key;
  let market = $project.settings.market;

  let tournamentLink = operator?.aff_links?.[projectKey]?.[market]?.tournaments || null;

  let ctaLink =
    $project.settings.redirectUrl && operator?.name_lower
      ? `/${$project.settings.redirectUrl}/${operator.name_lower}`
      : "";
  $: logoUrl = getOperatorLogo($project, operator.logo);
</script>

<!-- Logo Container -->
<div class="logo-container" style="background-color: rgb({primaryColor});">
  {#if ribbon}
    <Ribbon {operator} />
  {/if}
  <a
    href={isTournamentLink && tournamentLink ? tournamentLink : ctaLink}
    rel="nofollow noreferrer"
    data-element={dataElement}
    data-operator={operator.name_lower}
    data-type="logo"
    data-toplist-pos={toplistPosition}>
    <img class="logo" loading={(listIndex ?? 0) > 5 ? "lazy" : "eager"} alt={operator.name} src={logoUrl} />
  </a>
  {#if rating}
    <div class="rating">
      <Rating {operator} />
    </div>
  {/if}
</div>

<style>
  .logo-container {
    grid-area: LOGO;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 125px;
    max-width: 125px;
    height: 100%;
    max-height: 355px;
    padding: 10px;
    position: relative;
    @media only screen and (min-width: 992px) {
      max-width: 165px;
    }
  }

  .logo-container .logo {
    max-width: 100%;
    width: auto;
    margin: auto 0;
  }

  .logo-container .rating {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
</style>
