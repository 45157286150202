<script>
  export let operator;
  export let project;
  import { BadgeDollarSign } from "lucide-svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  let freespinTurnover =
    operator.bonus?.casino?.[$project.settings.market]?.freespins_turnover;

  let freeSpinsAmount =
    operator?.bonus?.casino?.[$project.settings.market]?.freespins_amount;
</script>

<div class="freespins-turnover">
  <div class="freespins-icon-mobile">
    <BadgeDollarSign size="20" strokeWidth="1" />
  </div>
  <div class="freespins-icon-desktop">
    <BadgeDollarSign strokeWidth="1" />
  </div>
  {#if freeSpinsAmount != null && freeSpinsAmount != undefined && freeSpinsAmount != 0}
    <span>
      <Translate text="Spin wagering requirements" />:
      <strong class="spin-wagering">
        {#if freespinTurnover != null && freespinTurnover != undefined && freespinTurnover >= 0}
          {freespinTurnover}x
        {:else}
          ?
        {/if}
      </strong>
    </span>
  {:else}
    <span><Translate text="Free spins not available" /></span>
  {/if}
</div>

<style>
  .freespins-turnover {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 300;
    font-size: 13px;
  }
  .freespins-icon-mobile {
    display: flex;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  .freespins-icon-desktop {
    display: none;
    @media screen and (min-width: 992px) {
      display: flex;
    }
  }
</style>
