<script>
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Perks from "./blocks/Perks.svelte";
  import Logo from "./blocks/Logo.svelte";
  import Badges from "./blocks/Badges.svelte";
  import ClickToAction from "./blocks/ClickToAction.svelte";
  import Bonus from "./blocks/Bonus.svelte";
  import { translate } from "$lib/common/helpers/translate";
  import { formatValue } from "$lib/common/helpers/formatValue";

  export let props = null;
  export let operator = null;
  export let project = null;
  export let visibleOrNot = null;
  export let dataElement = null;
  export let toplistPosition = null;

  let lang = $project.settings.lang ?? "en";
  let primaryColor =
    operator.visuals[$project.key][$project.settings.market].primary_color;

  $: formattedPrimaryColor = primaryColor.replace(/\s+/g, "");
  $: borderColor =
    formattedPrimaryColor === "255,255,255,1" ? "" : `rgba(${primaryColor})`;

  const toplistPaymentMethodProvider =
    props?.toplistPaymentMethodProvider?.toLowerCase() || "";

  const currency = $project.settings.currency.toUpperCase();

  const withdrawalExperience =
    operator.data.ownExperience?.withdrawalExperience;

  let totalResults = 0;
  let numberOfRounds = 0;

  for (const round in withdrawalExperience) {
    if (
      withdrawalExperience[round].payment_method.toLowerCase() ===
      toplistPaymentMethodProvider
    ) {
      totalResults += withdrawalExperience[round].result;
      numberOfRounds++;
    }
  }

  const withdrawalExperienceAverage =
    numberOfRounds === 0
      ? {
          label: translate(["Withdrawal Time estimated"], lang),
          value: formatValue(
            operator.data?.withdrawalData?.[$project.settings.market]?.[
              toplistPaymentMethodProvider
            ]?.estimated_time,
            "",
            { lang }
          ),
        }
      : {
          label: translate(["Withdrawal Time", "(tested)"], lang),
          value: formatValue(totalResults / numberOfRounds || 0, "min", {
            lang,
          }),
        };

  const payNPlay = operator.data.features?.[$project.settings.market].pay_n_play
    ? translate("Yes", lang)
    : translate("No2", lang);

  const withdrawalOptions = Object.values(
    operator.data.withdrawal?.[$project.settings.market] ?? []
  );

  const withdrawalData =
    operator.data.withdrawalData?.[$project.settings.market]?.[
      toplistPaymentMethodProvider
    ];

  const depositOptions = Object.values(
    operator.data.deposit?.[$project.settings.market] ?? []
  );

  const depositData =
    operator.data.depositData?.[$project.settings.market]?.[
      toplistPaymentMethodProvider
    ];

  const minDeposit = depositData?.min_deposit || 0;
  const minWithdrawal = withdrawalData?.min_withdrawal || 0;
  const withdrawalFee = withdrawalData?.fee || 0;

  const isAvailableWithdrawalMethod = withdrawalOptions.includes(
    toplistPaymentMethodProvider
  )
    ? translate("Yes", lang)
    : translate("No2", lang);
  const isAvailableDepositMethod = depositOptions.includes(
    toplistPaymentMethodProvider
  )
    ? translate("Yes", lang)
    : translate("No2", lang);

  const wagering =
    operator.data.withdrawalLimits?.min?.[$project.settings.market]
      ?.wagering === 0 ||
    operator.data.withdrawalLimits?.min?.[$project.settings.market]?.wagering >
      0
      ? `${operator.data.withdrawalLimits?.min?.[$project.settings.market]?.wagering}x`
      : translate("No2", lang);

  let perksData = [
    {
      title: translate(["Deposit", `(${toplistPaymentMethodProvider})`], lang),
      value: isAvailableDepositMethod,
    },
    {
      title: translate(
        ["Withdrawal", `(${toplistPaymentMethodProvider})`],
        lang
      ),
      value: isAvailableWithdrawalMethod,
    },
    {
      title: translate("Minimum deposit", lang),
      value: minDeposit ? `${minDeposit} ${currency}` : "-",
    },
    {
      title: translate("Pay'n Play", lang),
      value: payNPlay,
    },
    {
      title: withdrawalExperienceAverage.label,
      value: withdrawalExperienceAverage.value,
    },
    {
      title: translate("Min Withdrawal", lang),
      value: minWithdrawal ? `${minWithdrawal} ${currency}` : "-",
    },
    {
      title: translate(
        [`Withdrawal Fee`, `(${toplistPaymentMethodProvider})`],
        lang
      ),
      value: withdrawalFee ? `${withdrawalFee} ${currency}` : 0,
    },
    {
      title: translate("Wagering requirements on deposit", lang),
      value: wagering,
    },
  ];
</script>

<div style="display: {visibleOrNot}">
  <div class="operator-container" style="border-color: {borderColor};">
    <Logo {operator} {primaryColor} {dataElement} {toplistPosition} />
    <Badges {operator} {project} />
    <Bonus {operator} {project} />
    <Perks perks={perksData} theme={`payment-method`} />
    <ClickToAction
      {operator}
      {project}
      {props}
      {dataElement}
      {toplistPosition}
    />
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    position: relative;
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-template-rows: 0fr 55px 0fr 1fr;
    grid-template-areas:
      "LOGO BONUS"
      "LOGO BADGES"
      "LOGO CTA"
      "PERKS PERKS";
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid rgba(217, 217, 217, 1);
    overflow: hidden;
    @media only screen and (min-width: 992px) {
      height: 190px;
      grid-auto-flow: column;
      grid-template-columns: 165px 55px 1.2fr 1.7fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA";
    }
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    & a {
      color: var(--clr-qua);
      text-decoration: underline;
      font-weight: 300;
    }
  }
</style>
