<script>
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Perks from "./blocks/Perks.svelte";
  import Logo from "./blocks/Logo.svelte";
  import Badges from "./blocks/Badges.svelte";
  import ClickToAction from "./blocks/ClickToAction.svelte";
  import Bonus from "./blocks/Bonus.svelte";
  import { translate } from "$lib/common/helpers/translate";
  import { formatValue } from "$lib/common/helpers/formatValue";
  import { calculateAverageExperience, calculateAverageRanking } from "$lib/common/helpers/calculateAverage";

  export let props = null;
  export let operator = null;
  export let project = null;
  export let visibleOrNot = null;
  export let dataElement = null;
  export let toplistPosition = null;

  let lang = $project.settings.lang ?? "en";
  let primaryColor = operator.visuals[$project.key][$project.settings.market].primary_color;

  $: formattedPrimaryColor = primaryColor.replace(/\s+/g, "");
  $: borderColor = formattedPrimaryColor === "255,255,255,1" ? "" : `rgba(${primaryColor})`;

  // Perks Data:
  // Wagering requirements
  let withdrawalWagering = operator?.data?.withdrawalLimits?.["min"]?.[$project.settings.market]?.wagering;

  // Withdrawal experience 1 & 2
  let withdrawalExperienceRound1 = operator?.data?.ownExperience?.withdrawalExperience?.round1;
  let withdrawalExperienceRound2 = operator?.data?.ownExperience?.withdrawalExperience?.round2;

  // Average Speed experience
  let averageSpeedExperience = calculateAverageExperience(
    Object.values(operator?.data?.ownExperience?.speedExperience ?? {}),
  );

  // Average Chat experience
  let averageChatExperience = calculateAverageExperience(
    Object.values(operator?.data?.ownExperience?.livechatExperience ?? {}),
  );

  // Average for review websites ranking score
  let averageReviewRanking = calculateAverageRanking(operator?.data?.rankingSections?.[$project.settings.market]);

  let perksData = [
    {
      title: translate("Wagering requirements on deposit", lang),
      value: formatValue(withdrawalWagering, "x"),
    },
    {
      title: translate(["Withdrawal attempt", "One"], lang),
      value: formatValue(withdrawalExperienceRound1?.result, "min", {
        paymentMethod: withdrawalExperienceRound1?.payment_method,
        lang,
      }),
    },
    {
      title: translate(["Withdrawal attempt", "Two"], lang),
      value: formatValue(withdrawalExperienceRound2?.result, "min", {
        paymentMethod: withdrawalExperienceRound2?.payment_method,
        lang,
      }),
    },
    {
      title: translate("Find Starbust (Speed)", lang),
      value: formatValue(averageSpeedExperience, "sec", { lang }),
    },
    {
      title: translate("Average chat response time", lang),
      value: formatValue(averageChatExperience, "min", { lang }),
    },
    {
      title: translate("Rank from other review sites", lang),
      value: formatValue(averageReviewRanking),
    },
  ];
</script>

<div style="display: {visibleOrNot}">
  <div class="operator-container" style="border-color: {borderColor};">
    <Logo {operator} {primaryColor} {dataElement} {toplistPosition} />
    <Badges {operator} {project} />
    <Bonus {operator} {project} />
    <Perks perks={perksData} />
    <ClickToAction {operator} {project} {props} {dataElement} {toplistPosition} />
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    position: relative;
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-template-rows: 0fr 55px 0fr 1fr;
    grid-template-areas:
      "LOGO BONUS"
      "LOGO BADGES"
      "LOGO CTA"
      "PERKS PERKS";
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid rgba(217, 217, 217, 1);
    overflow: hidden;
    @media only screen and (min-width: 992px) {
      height: 190px;
      grid-auto-flow: column;
      grid-template-columns: 165px 55px 1.2fr 1.7fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA";
    }
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    & a {
      color: var(--clr-qua);
      text-decoration: underline;
      font-weight: 300;
    }
  }
</style>
