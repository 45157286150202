<script lang="ts">
  import { project } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import { getOperatorLogo } from "$lib/common/helpers/image";

  export let operator: Operator;
  export let primaryColor: string;
  export let listIndex: number | null = null;
  export let dataElement: string;
  export let toplistPosition: number;

  let ctaLink = `/${$project.settings.redirectUrl}/${operator?.name_lower}` ?? "";
  $: logoUrl = getOperatorLogo($project, operator.logo);
</script>

<div class="logo-container" style="background-color: rgb({primaryColor});">
  <a
    href={ctaLink}
    rel="nofollow noreferrer"
    data-element={dataElement}
    data-operator={operator.name_lower}
    data-type="logo"
    data-toplist-pos={toplistPosition}>
    <img class="logo" loading={(listIndex ?? 0) > 5 ? "lazy" : "eager"} alt={operator.name} src={logoUrl} />
  </a>
</div>

<style>
  .logo-container {
    grid-area: LOGO;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% + 10px);
    height: 105px;
    padding: 30px;
    position: absolute;
    left: -5px;
    top: -5px;

    @media only screen and (min-width: 992px) {
      height: 100px;
      width: 100%;
      position: unset;
    }
  }

  .logo-container .logo {
    width: 140px;
    object-fit: contain;
    height: 100%;
    max-height: 100px;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate(0, -50%);

    @media only screen and (min-width: 992px) {
      position: unset;
      transform: translate(0, 0);
    }
  }
</style>
