import type { RankingSections } from "@/src/types/components/operators/Ranking";

type ExperienceItem = number | { result: number };
type RankingItem = { rating_total: number; rating_value: number };

export function calculateAverageExperience(items: Array<ExperienceItem | RankingItem>): number | string {
  let total = 0;
  let count = 0;

  items.forEach((item) => {
    const value = typeof item === "object" ? (item as { result: number }).result : item;
    if (typeof value === "number" && !isNaN(value)) {
      total += value;
      count++;
    }
  });

  if (count > 0) {
    let average = total / count;
    return +average.toFixed(2);
  }

  return "-";
}

export function calculateAverageRanking(data: RankingSections) {
  if (!data) return 0;

  try {
    const values = [data.games_experience, data.games_sortiment, data.payments, data.support];

    const sum = values.reduce((acc, value) => acc + value, 0);
    const average = sum / 4;

    return parseFloat(average.toFixed(1));
  } catch (error) {
    console.error("Error calculating average:", error);
    return 0;
  }
}
