<script lang="ts">
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";

  export let props: any = null;
  export let adminView: boolean = false;
</script>

{#if adminView}
  <Group colspan="8-2-2" addClass="form-item">
    <Input placeholder="Heading" bind:value={props.headingText} on:keyup />
    <Input type="select" bind:value={props.headingSize} on:change>
      <option value="h1">H1</option>
      <option value="h2">H2</option>
      <option value="h3">H3</option>
      <option value="h4">H4</option>
      <option value="h5">H5</option>
      <option value="h6">H6</option>
    </Input>
    <Input type="select" bind:value={props.headingTextAlign} on:change>
      <option value="">Left</option>
      <option value="ta-c">Center</option>
      <option value="ta-r">Right</option>
    </Input>
  </Group>
{:else if props.headingText}
  <svelte:element
    this={props.headingSize}
    class={props.headingTextAlign ? props.headingTextAlign : ""}
  >
    {props.headingText ? props.headingText : ""}
  </svelte:element>
{/if}
