<script lang="ts">
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import { page } from "$app/stores";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import RatingNew from "$lib/admin/components/blocks/helpers/RatingNew.svelte";
  import { getColor } from "$lib/common/helpers/getColor";

  export let rating = true;
  export let operator: Operator;
  export let project;
  export let blackColor: boolean = false;

  let reviewLink = `/${$project.data?.categoryPages.reviews.slug}/${operator?.name_lower}` ?? "";

  const defaultStarBgColor = "0, 0, 0, 0"; // Fallback star color

  const textColor = getColor(
    defaultStarBgColor,
    operator?.visuals?.[$project.key]?.[$project.settings.market]?.["secondary_color"],
    blackColor,
  );
</script>

<div class="cta-container" style="--text-color: {textColor};">
  {#if rating}
    <RatingNew {operator} />
  {/if}
  {#if $page.url.pathname != `/${$project.data?.categoryPages.reviews.slug}/${operator.name_lower}` && reviewLink}
    <a class="cta-review" href={reviewLink}><Translate text="ReadReview" /></a>
  {/if}
</div>

<style>
  .cta-container {
    grid-area: RATINGREVIEW;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    position: absolute;
    right: 40px;
    top: 30px;

    @media only screen and (min-width: 992px) {
      position: unset;
    }
  }
  .cta-container .cta-review {
    width: 100%;
    font-size: 13px;
    text-align: center;
    font-family: "Montserrat";
    font-style: italic;
    text-decoration: underline;
    font-weight: 600;
    color: var(--text-color);

    @media only screen and (min-width: 992px) {
      color: #333333;
    }
  }
</style>
