<script lang="ts">
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import type { Operator } from "@/src/types/components/operators/Operator";
  export let operator: Operator;
  export let project;
  export let props: Record<string, any> = {};
  export let dataElement: string;
  export let toplistPosition: number;
</script>

<div class="cta-container">
  <!-- Operator External Link -->
  <a
    href={`/${$project.settings.redirectUrl}/${operator.name_lower}`}
    target="_blank"
    rel="nofollow noreferrer noopener"
    class="cta"
    data-element={dataElement}
    data-operator={operator.name_lower}
    data-type="button"
    data-toplist-pos={toplistPosition}>
    <Translate text={"Visit" + (props.toplistFocus == "casino" ? " casino" : "")} />
    <br class="line-break" />
    <span class="casino-name">
      {operator["name"]}
    </span>
  </a>
</div>

<style>
  .cta-container {
    grid-area: CTA;
    width: 100%;
    margin: 0;

    @media only screen and (min-width: 992px) {
      width: 180px;
      padding: 5px 5px 5px 0;
      justify-content: center;
    }
  }

  .cta-container .cta {
    width: 100%;
    height: 100%;
    font-size: 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 6px;
    background-color: var(--clr-cta);
    text-align: center;
    padding: 5px;
    flex-flow: row;
    gap: 3px;

    @media only screen and (min-width: 992px) {
      gap: 0;
      flex-flow: column;
    }
  }

  .cta-container .cta .casino-name {
    font-weight: 400;
    text-transform: uppercase;
  }

  .cta-container .cta .line-break {
    display: none;

    @media only screen and (min-width: 992px) {
      display: block;
    }
  }
</style>
