<script>
  export let type = 'basic'
</script>

<div class="alert {type}" role="alert">
  <slot />
</div>

<style>
  .alert {
    padding: 1rem;
    color: var(--txt-clr-3);
    border-radius: var(--bd-rad);
    margin: 1.5rem 0 0 0;
    font-size: .875rem;
  }

  :global(.alert a) {
    text-decoration: underline;
  }

  .basic {
    background-color: var(--bg-clr-ltr);
  }

  .red, .negative, .danger {
    background-color: #332020;
    color: var(--clr-sec);
    border: 1px solid #3e2725;
  }

  .green, .positive, .success {
    background-color: #172923;
    color: var(--clr-pri);
    border: 1px solid #18382d;
  }

  .yellow, .added, .info {
    background-color: #32281c;
    color: var(--clr-qua);
    border: 1px solid #463627;
  }
</style>