<script lang="ts">
  export let props: any = null;
  export let blockClass: string = "";
  export let gridColumn: string = "g-col-12";
</script>

<div
  class={"block" + (blockClass ? " " + blockClass : "")}
  id={props?.tocData?.tag}
>
  <div class="container">
    <div class="grid">
      <div class={gridColumn}>
        <slot />
      </div>
    </div>
  </div>
</div>
