<script lang="ts">
  import { project } from "$lib/services/store";
  import { getColor } from "$lib/common/helpers/getColor";
  import type { Operator } from "@/src/types/components/operators/Operator";
  export let operator: Operator;
  export let blackColor: boolean = false;

  let minRating = 0;
  let maxRating = 5;

  let rating = operator?.rating?.[$project.key]?.[$project.settings.market]?.rating ?? minRating;
  let ratingMessage = operator?.rating?.[$project.key]?.rating
    ? $project.name +
      " rates " +
      operator.name +
      " " +
      operator?.rating?.[$project.key]?.rating +
      " out of " +
      maxRating +
      " stars"
    : operator?.name + " has not yet been given a rating by " + $project.name;

  const defaultStarBgColor = "0, 0, 0, 0"; // Fallback star color
  const defaultStarColor = "230, 230, 230, 1"; // Fallback text color

  const starBgColor = getColor(
    defaultStarBgColor,
    operator?.visuals?.[$project.key]?.[$project.settings.market]?.["primary_color"],
    blackColor,
  );
  const starColor = getColor(
    defaultStarColor,
    operator?.visuals?.[$project.key]?.[$project.settings.market]?.["secondary_color"],
    blackColor,
  );
</script>

<div
  class="item-rating"
  style="--rating: {rating}; --star-color: {starBgColor}; color: {starColor} "
  aria-label={ratingMessage}
  title={ratingMessage}>
  <span>★★★★★</span>
</div>
