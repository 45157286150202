<script>
  export let title;
  export let value;
  export let gridArea;
  export let theme = "default";
</script>

<div class={`perk-container ${theme}`} style="grid-area: {gridArea};">
  <span class="value">{value}</span>
  <span class="title">{title}</span>
</div>

<style>
  .perk-container {
    padding: 3px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #f5f5f5;
    text-align: center;

    @media only screen and (min-width: 992px) {
      margin: 5px 0;
    }
  }

  .value {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    font-family: "Montserrat";
    font-style: italic;
    text-transform: capitalize;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    color: #000;
    padding: 0 25px;
    @media only screen and (min-width: 992px) {
      padding: 0 10px;
    }
  }
</style>
