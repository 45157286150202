<script lang="ts">
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Logo from "./blocks/Logo.svelte";
  import type { Operator } from "@/src/types/components/operators/Operator";

  export let operator: Operator;
  export let project;
  export let visibleOrNot = null;
  export let dataElement = "";
  export let toplistPosition;

  let market = $project.settings.market;
  let primaryColor = operator?.visuals?.[$project.key]?.[market]?.primary_color ?? "#000000";
</script>

<div style="display: {visibleOrNot}">
  <div class="operator-container tournaments">
    <Logo
      {operator}
      {primaryColor}
      {dataElement}
      {toplistPosition}
      rating={false}
      ribbon={false}
      isTournamentLink={true} />
    <div>
      <h2 class="sponsor-name">{operator.name}</h2>
      <p class="sponsor-description">
        {operator?.bonus?.casino[market]?.full || "No description"}
      </p>
    </div>
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    position: relative;
    display: grid;
    height: 70px;
    gap: 20px;
    align-items: center;
    grid-template-columns: 125px 1fr;
    grid-template-areas: "LOGO CONTENT";
    border-radius: 4px;
    background-color: #3f3f3f;
    color: #fff;
    overflow: hidden;
  }
  :global(.operator-container.tournaments .logo-container a img) {
    max-height: 51px;
  }
  :global(.operator-container.tournaments .logo-container a) {
    max-height: 51px;
    font-size: 0;
    line-height: 0;
  }
  .sponsor-name {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .sponsor-description {
    margin: 0;
    font-size: 14px;
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    padding: 4px;
    font-weight: 300;
    overflow-y: auto;
    height: 47px;
    margin: 4px 5px 0 5px;
    & a {
      color: var(--clr-qua);
      text-decoration: underline;
      font-weight: 300;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #e0e0e0;
      border: 6px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #cc3129;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #a82720;
    }
  }
</style>
