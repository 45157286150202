<script lang="ts">
  import { previewActiveModal } from "$lib/services/store";
  import Button from "$lib/main/components/Button.svelte";
  import { onDestroy } from "svelte";

  export let toggleModal = false;
  export let removeHeader = false;
  export let addClass: string = "";
  export let fullWidth: boolean = false;
  let dialogElement: HTMLDialogElement;

  const disableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.add("no-scroll");
    }
  };

  const enableBodyScroll = () => {
    if (typeof window !== "undefined") {
      document.body.classList.remove("no-scroll");
    }
  };

  $: if (toggleModal) {
    dialogElement?.showModal();
    disableBodyScroll();
  } else {
    dialogElement?.close();
    enableBodyScroll();
  }

  const handleClick = (event) => {
    if (event.target.className.includes('modal-box')) {
      closeModal();
    }
  }

  const closeModal = () => {
    toggleModal = false;
    $previewActiveModal = false;
    enableBodyScroll();
  };

  onDestroy(() => {
    enableBodyScroll();
  });
</script>

{#if toggleModal}
  <div class={`modal`}>
    <div class="overlay" on:click={closeModal}></div>
    <dialog
      bind:this={dialogElement}
      class={"modal-box" + (addClass ? " " + addClass : "")}
      style={`${fullWidth ? "left:0; max-width:100%; width:100%;" : ""}`}
      on:cancel={closeModal}
      on:click={handleClick}
    >
      {#if !removeHeader}
        <header class="flex-sb-c">
          <h4><slot name="header">Modal header</slot></h4>
          <Button
            addClass="secondary small"
            on:click={closeModal}
          >Close</Button>
        </header>
      {/if}

      <div class="content">
        <slot />
      </div>
    </dialog>
  </div>
{/if}

<style>
  .overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  dialog[open] {
    animation: fadeIn 0.3s;
  }

  dialog {
    padding: 0;
  }

  dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  .search {
    margin-top: 3rem;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
