export const website = (project) => {
  return `<script type="application/ld+json">
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "@id": "${project.settings.url + "#website"}",
      "name": "${project.settings.domain}",
      "url": "${project.settings.url}"
    }
  </script>`;
}

export const organization = (project) => {
  return `<script type="application/ld+json">
    {
      "@context" : "http://schema.org",
      "@type": "Organization",
      "@id": "${project.settings.url + "#organization"}",
      "name" : "${project.settings.domain}",
      "url" : "${project.settings.url}",
      "alternateName" : "${project.name}",
      "logo": "${project.data?.imageFolders?.assets + project.name_lower + "-logo.png"}",
      "foundingDate": "${project.settings.foundedYear}",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer service",
        "email": "${project.data.contact.email}",
        "url": "${project.settings.url + project.data.contact.url}"
      },
      "sameAs" : []
    }
  </script>`;
}

export const article = (post, users, project) => {
  let article = `<script type="application/ld+json">`

  article = article + `{
    "@context" : "http://schema.org",
    "@type": "Article",
    "@id": "${project.settings.url}#article",
    "url": "${project.settings.url}",
    "name": "${post?.title}",`

  if (post && post.user_id) {
    const user = users && users.find(x => x.id === post.user_id);
    if (user && user.data && project && project.key in user.data) {
      const userData = user.data[project.key];
      if (userData) {
        article = article + `
        "author": {
          "@type": "Person",
          "name": "${user.full_name}",
          "jobTitle": "${userData.role}",
          "description": "${userData.bio}",
          "email": "${userData.contact_email}",
          "sameAs": "${userData.linkedin}"
        },
        "editor": "${user.full_name}",`;
      }
    }
  }

  article = article + `
    "datePublished": "${post?.created_at}",
    "dateModified": "${post?.updated_at}",
    "isPartOf": {
      "@id": "${project.settings.url}#website"
    },
    "inLanguage": "${project.settings.lang}"
  }
  </script>`

  return article
}

export const faq = (data) =>  {
  let mainEntity = []

  data.forEach(question => mainEntity.push(JSON.stringify({
    "@type": "Question",
    "name": question.header,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": question.content
    }
  })))

  return `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [${mainEntity}]
    }
  </script>`;
}

export const ogData = (post, project, domain, page_path) => {
  let title = post?.seo?.page_title ?? project.name
  let url = domain + page_path
  let featured_image = post?.image_featured ? `${project.data.imageFolders.media}${post?.image_featured}` : `${project.data.imageFolders.media}casinofeber-se-fb-share.jpg`

  let ogDataResponse = ""

  if (title) {
    ogDataResponse += `<meta property="og:title" content="${title}">
    <meta property="og:type" content="article">`
  }
  if (url) {
    ogDataResponse += `<meta property="og:url" content="${url}">`
  }
  if (featured_image) {
    ogDataResponse += `<meta property="og:image" content="${featured_image}">`
  }

  return ogDataResponse
}

export const generalHeadTags = (post, users, project, inProduction = false, domain, page_path) => {
  let lastModified = post?.updated_at?.split('T')[0]
  let canonicalUrl = domain + page_path.split("#")[0]
  let articleAuthor = users?.length > 0 ? users?.find(user => user.id === post.user_id)?.display_name : null
  let applicationName = project.name

  let rel_prev = post?.seo?.rel_prev ? `${domain + post?.seo?.rel_prev}` : null

  let verification = project.settings.verification
  let verificationAhrefs = project.settings.verificationAhrefs
  let verificationGoogleSite = project.settings.verificationGoogleSite

  let generalHeadTagsResponse = ""

  if (lastModified) {
    generalHeadTagsResponse += `<meta http-equiv="last-modified" content="${lastModified}">`
  }
  if (canonicalUrl) {
    generalHeadTagsResponse += `<link rel="canonical" href="${canonicalUrl}">`
  }
  if (articleAuthor) {
    generalHeadTagsResponse += `
      <meta name="author" content="${articleAuthor}">
      <meta property="article:author" content="${articleAuthor}">
    `
  }
  if (applicationName) {
    generalHeadTagsResponse += `
      <meta name="application-name" content="${applicationName}">
      <meta name="apple-mobile-web-app-title" content="${applicationName}">
    `
  }
  if (rel_prev) {
    generalHeadTagsResponse += `
      <link rel="prev" href="${rel_prev}">
    `
  }

  if (verification && inProduction) {
    generalHeadTagsResponse += `<meta name="verification" content="${verification}">`
  }
  if (verificationAhrefs && inProduction) {
    generalHeadTagsResponse += `<meta name="ahrefs-site-verification" content="${verificationAhrefs}">`
  }
  if (verificationGoogleSite && inProduction) {
    generalHeadTagsResponse += `<meta name="google-site-verification" content="${verificationGoogleSite}">`
  }

  return generalHeadTagsResponse
}

export const breadcrumbs = (project, path, postData) => {

  let itemListElement = []
  let paths = path.split("/")
  let pathBuilt = project.settings.url

  paths.forEach((url, i) => {
    if (url === "" && i == 0) {
      itemListElement.push(JSON.stringify({
        "@type": "ListItem",
        "position": i + 1,
        "name": project.name,
        "item": pathBuilt
      }))
    } else if (paths.length > 2 && i == 1) {
      pathBuilt += "/" + url
      itemListElement.push(JSON.stringify({
        "@type": "ListItem",
        "position": paths.indexOf(url) + 1,
        "name": postData?.parent?.seo?.breadcrumb ?? postData?.title,
        "item": pathBuilt
      }))
    } else {
      pathBuilt += "/" + url
      itemListElement.push(JSON.stringify({
        "@type": "ListItem",
        "position": paths.indexOf(url) + 1,
        "name": postData?.seo?.breadcrumb ?? postData?.title,
        "item": pathBuilt
      }))
    }
  })

  return `<script type="application/ld+json">
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "@id": "${pathBuilt}#breadcrumb",
      "itemListElement": [${itemListElement}]
    }
  </script>`
}